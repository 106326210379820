import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {
    Message,
    Modal,
    Breadcrumb,
    Card,
    Grid,
    Form,
    Space,
    Button,
    Divider,
    Table,
    Pagination,
    Input,
    Dropdown,
    Menu,
    Select,
    Tag,
} from "@arco-design/web-react";
import {IconSearch, IconUndo, IconPlusCircle, IconEye, IconDelete, IconUpload, IconDownload} from "@arco-design/web-react/icon";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useOutletContext} from "react-router-dom";
import PropTypes from "prop-types";

import Util from "../../../../../nowui-common/js/util";

import Error from "../../../../../nowui-common/component/error";
import Import from "../../../../../nowui-common/component/import";
import Process from "../../../../../nowui-common/component/process";
import Loading from "../../../../../nowui-common/component/loading";
import Selected from "../../../../../nowui-common/component/selected";
import Sort from "../../../../../nowui-common/component/sort";

import RechargeRecordDetail from "./detail";

const RechargeRecordIndex = ({autoHeight = false, selectType = "checkbox", onSelect = () => {}, ...props}) => {
    const outletContext = useOutletContext();
    const main = useSelector((store) => store.main);
    const controller = new AbortController();
    const request = useRef(false);
    const [form] = Form.useForm();
    const columnListRef = useRef([]);
    const [columnList, setColumnList] = useState([]);
    const [operationColumn, setOperationColumn] = useState({});
    const [isLoad, setIsLoad] = useState(false);
    const [isDetail, setIsDetail] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const [processType, setProcessTyp] = useState(0);
    const [isImport, setIsImport] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [selectedIdList, setSelectedIdList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorList, setErrorList] = useState([]);
    const [breadcrumbList, setBreadcrumbList] = useState([]);
    const [action, setAction] = useState("");
    const [recordId, setRecordId] = useState("");
    const [rechargeRecordCount, setRechargeRecordCount] = useState(0);
    const [rechargeRecordList, setRechargeRecordList] = useState([]);
    const [rechargeRecordPageIndex, setRechargeRecordPageIndex] = useState(1);
    const [rechargeRecordPageSize, setRechargeRecordPageSize] = useState(10);
    const [sortList, setSortList] = useState([]);

    useEffect(() => {
        handleSearchRechargeRecord();

        return () => {
            controller.abort();
        };
    }, []);

    useEffect(() => {
        setErrorTitle("");
        setErrorList([]);

        let breadcrumbList = Util.getBreadcrumb(outletContext, main.menuList, "/recharge/record/index");
        setBreadcrumbList(breadcrumbList);

        let columnList = [
            {
                dataIndex: "recordStatus",
                title: outletContext.intl.formatMessage({id: "rechargeRecord.recordStatus"}),
                sorter: {
                    multiple: 1,
                },
                sortOrder: "",
                render: (col, record, index) => (
                    <React.Fragment>
                        {record.recordStatus == 0 ? (
                            <Tag color="#00b42a" size="medium">
                                {record.recordStatusLabel}
                            </Tag>
                        ) : null}
                        {record.recordStatus == 1 ? (
                            <Tag color="#165dff" size="medium">
                                {record.recordStatusLabel}
                            </Tag>
                        ) : null}
                        {record.recordStatus == 2 ? (
                            <Tag color="#f53f3f" size="medium">
                                {record.recordStatusLabel}
                            </Tag>
                        ) : null}
                    </React.Fragment>
                ),
            },
            {
                dataIndex: "rechargeName",
                title: outletContext.intl.formatMessage({id: "rechargeRecord.rechargeName"}),
            },
            {
                dataIndex: "rechargeAmount",
                title: outletContext.intl.formatMessage({id: "rechargeRecord.rechargeAmount"}),
            },
            {
                dataIndex: "rechargeValue",
                title: outletContext.intl.formatMessage({id: "rechargeRecord.rechargeValue"}),
            },
            {
                dataIndex: "memberPhone",
                title: outletContext.intl.formatMessage({id: "rechargeRecord.memberPhone"}),
            },
            {
                dataIndex: "memberName",
                title: outletContext.intl.formatMessage({id: "rechargeRecord.memberName"}),
            },
            {
                dataIndex: "createTime",
                title: outletContext.intl.formatMessage({id: "global.createTime"}),
            },
        ];
        setColumnList(columnList);

        setOperationColumn({
            width: 110,
            fixed: "right",
            dataIndex: "operation",
            title: outletContext.intl.formatMessage({id: "global.operation"}),
            render: (col, record, index) => (
                <React.Fragment>
                    {main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/item")) > -1 ? (
                        <Button
                            type="default"
                            icon={<IconEye />}
                            onClick={() => {
                                setIsDetail(true);
                                setAction("update");
                                setRecordId(record.recordId);
                            }}
                        >
                            {outletContext.intl.formatMessage({id: "global.view"})}
                        </Button>
                    ) : null}
                </React.Fragment>
            ),
        });

        Util.changeSortList(sortList, setSortList, columnList);
    }, [localStorage.getItem("locale")]);

    useEffect(() => {
        onSelect(selectedList);
    }, [selectedList]);

    useEffect(() => {
        columnListRef.current = columnList;
    }, [columnList]);

    useEffect(() => {
        let columnList = columnListRef.current;
        if (columnList.length == 0) {
            return;
        }
        for (let i = 0; i < columnList.length; i++) {
            let direction = "";
            for (let j = 0; j < sortList.length; j++) {
                if (columnList[i].dataIndex == sortList[j].field) {
                    direction = sortList[j].direction;
                    break;
                }
            }
            columnList[i].sortOrder = direction;
        }
        setColumnList([...columnList]);

        handleSearchRechargeRecord();
    }, [sortList]);

    const handleSearchRechargeRecord = () => {
        handleLoadRechargeRecordList(1, rechargeRecordPageSize);
    };

    const handleLoadRechargeRecordList = (rechargeRecordPageIndex, rechargeRecordPageSize) => {
        if (main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/list")) == -1) {
            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = form.getFieldsValue();
        data.sortList = sortList;
        data.pageIndex = rechargeRecordPageIndex;
        data.pageSize = rechargeRecordPageSize;

        Util.request(outletContext, {
            url: process.env.API_URL + "/recharge/record/backend/website/v1/list",
            controller: controller,
            data: data,
            success: (response) => {
                setRechargeRecordCount(response.count);
                setRechargeRecordList(response.data);
                setRechargeRecordPageIndex(rechargeRecordPageIndex);
                setRechargeRecordPageSize(rechargeRecordPageSize);

                request.current = false;
                setIsLoad(false);
            },
            error: (response) => {
                Message.error(response.message);

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleDeleteRechargeRecord = () => {
        if (selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }

        Modal.confirm({
            title: outletContext.intl.formatMessage({id: "global.deleteConfirmTitle"}),
            content: outletContext.intl.formatMessage({id: "global.deleteConfirmContent"}),
            okButtonProps: {
                status: "danger",
            },
            onOk: () => {
                request.current = true;

                Message.loading({
                    id: "loading",
                    content: outletContext.intl.formatMessage({id: "global.loading"}),
                    duration: 0,
                });

                setIsLoad(true);
                setErrorTitle("");
                setErrorList([]);

                Util.request(outletContext, {
                    url: process.env.API_URL + "/recharge/record/backend/website/v1/delete",
                    controller: controller,
                    data: {
                        recordIdList: selectedIdList,
                    },
                    success: (response) => {
                        Message.success({
                            id: "loading",
                            content: response.message,
                        });

                        request.current = false;
                        setIsLoad(false);

                        setSelectedIdList([]);
                        setSelectedList([]);

                        handleLoadRechargeRecordList(rechargeRecordPageIndex, rechargeRecordPageSize);
                    },
                    error: (response) => {
                        Message.error({
                            id: "loading",
                            content: response.message,
                        });

                        setErrorTitle(response.message);
                        setErrorList(response.error);

                        request.current = false;
                        setIsLoad(false);
                    },
                    complete: () => {},
                });
            },
        });
    };

    const handleExportRechargeRecordSearch = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        let data = form.getFieldsValue();
        data.sortList = sortList;

        Util.request(outletContext, {
            url: process.env.API_URL + "/recharge/record/backend/website/v1/export/search",
            controller: controller,
            data: data,
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);

                setIsProcess(true);
                setProcessTyp(1);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    const handleExportRechargeRecordSelected = () => {
        if (selectedIdList.length == 0) {
            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        Message.loading({
            id: "loading",
            content: outletContext.intl.formatMessage({id: "global.loading"}),
            duration: 0,
        });

        setIsLoad(true);
        setErrorTitle("");
        setErrorList([]);

        Util.request(outletContext, {
            url: process.env.API_URL + "/recharge/record/backend/website/v1/export/selected",
            controller: controller,
            data: {
                recordIdList: selectedIdList,
            },
            success: (response) => {
                Message.success({
                    id: "loading",
                    content: response.message,
                });

                request.current = false;
                setIsLoad(false);

                setIsProcess(true);
                setProcessTyp(1);
            },
            error: (response) => {
                Message.error({
                    id: "loading",
                    content: response.message,
                });

                setErrorTitle(response.message);
                setErrorList(response.error);

                request.current = false;
                setIsLoad(false);
            },
            complete: () => {},
        });
    };

    return (
        <React.Fragment>
            <Scrollbars
                autoHeight={autoHeight}
                autoHeightMax={main.height - 300}
                renderView={({style, ...props}) => {
                    return <div className="scrollbar" style={style} {...props} />;
                }}
            >
                <Breadcrumb className="breadcrumb">
                    {breadcrumbList.map((breadcrumb, breadcrumbIndex) => {
                        return <Breadcrumb.Item key={breadcrumbIndex}>{breadcrumb.name}</Breadcrumb.Item>;
                    })}
                    {props.breadcrumb ? <Breadcrumb.Item key={props.breadcrumb.name}>{props.breadcrumb.name}</Breadcrumb.Item> : null}
                </Breadcrumb>
                <Error errorTitle={errorTitle} errorList={errorList} setErrorTitle={setErrorTitle} setErrorList={setErrorList} />
                <Form form={form} autoComplete="off" layout="vertical">
                    <Card className="card" title={outletContext.intl.formatMessage({id: "global.searchTable"})} bordered={false}>
                        <Grid.Row gutter={20}>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "rechargeRecord.recordStatus"})} field="recordStatus">
                                    <Select
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onChange={(event) => {
                                            handleSearchRechargeRecord();
                                        }}
                                    >
                                        <Select.Option value={0}>待支付</Select.Option>
                                        <Select.Option value={1}>已完成</Select.Option>
                                        <Select.Option value={2}>已取消</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "rechargeRecord.rechargeName"})} field="rechargeName">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchRechargeRecord();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "rechargeRecord.memberPhone"})} field="memberPhone">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchRechargeRecord();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                            <Grid.Col xs={24} sm={12} md={8} lg={6} xl={4}>
                                <Form.Item label={outletContext.intl.formatMessage({id: "rechargeRecord.memberName"})} field="memberName">
                                    <Input
                                        placeholder={outletContext.intl.formatMessage({id: "global.pleaseEnter"})}
                                        allowClear
                                        onPressEnter={() => {
                                            handleSearchRechargeRecord();
                                        }}
                                    />
                                </Form.Item>
                            </Grid.Col>
                        </Grid.Row>
                        <Space className="action" wrap>
                            {main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/list")) > -1 ? (
                                <React.Fragment>
                                    <Button
                                        type="primary"
                                        icon={<IconSearch />}
                                        loading={isLoad}
                                        onClick={() => {
                                            handleSearchRechargeRecord();
                                        }}
                                    >
                                        {outletContext.intl.formatMessage({id: "global.search"})}
                                    </Button>
                                    <Button
                                        icon={<IconUndo />}
                                        loading={isLoad}
                                        onClick={() => {
                                            form.resetFields();

                                            setSelectedIdList([]);
                                            setSelectedList([]);
                                            setSortList([]);
                                        }}
                                    >
                                        {outletContext.intl.formatMessage({id: "global.reset"})}
                                    </Button>
                                </React.Fragment>
                            ) : null}
                            {main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/save")) > -1 ||
                            main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/delete")) > -1 ? (
                                <React.Fragment>
                                    <Divider type="vertical" />
                                    {main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/save")) > -1 ? (
                                        <Button
                                            icon={<IconPlusCircle />}
                                            onClick={() => {
                                                setIsDetail(true);
                                                setAction("save");
                                                setRecordId("");
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.save"})}
                                        </Button>
                                    ) : null}
                                    {main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/delete")) > -1 ? (
                                        <Button
                                            icon={<IconDelete />}
                                            disabled={selectedIdList.length == 0}
                                            onClick={() => {
                                                handleDeleteRechargeRecord();
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.delete"})}
                                        </Button>
                                    ) : null}
                                </React.Fragment>
                            ) : null}
                            {main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/upload/process")) > -1 ||
                            main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/export/search")) > -1 ? (
                                <React.Fragment>
                                    <Divider type="vertical" />
                                    {main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/upload/process")) > -1 ? (
                                        <Button
                                            icon={<IconUpload />}
                                            onClick={() => {
                                                setIsImport(true);
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.import"})}
                                        </Button>
                                    ) : null}
                                    {main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/export/search")) > -1 ? (
                                        <Dropdown
                                            droplist={
                                                <Menu
                                                    onClickMenuItem={(key) => {
                                                        if (key == "search") {
                                                            handleExportRechargeRecordSearch();
                                                        } else if (key == "selected") {
                                                            handleExportRechargeRecordSelected();
                                                        } else if (key == "history") {
                                                            setIsProcess(true);
                                                            setProcessTyp(1);
                                                        }
                                                    }}
                                                >
                                                    <Menu.Item key="search">{outletContext.intl.formatMessage({id: "global.export"})}</Menu.Item>
                                                    <Menu.Item key="selected" disabled={selectedIdList.length == 0}>
                                                        {outletContext.intl.formatMessage({id: "global.exportSelected"})} {selectedIdList.length}
                                                    </Menu.Item>
                                                    <Divider style={{margin: "4px 0"}} />
                                                    <Menu.Item key="history">
                                                        {outletContext.intl.formatMessage({id: "global.exportHistory"})}
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                            trigger="click"
                                        >
                                            <Button icon={<IconDownload />}>{outletContext.intl.formatMessage({id: "global.export"})}</Button>
                                        </Dropdown>
                                    ) : null}
                                </React.Fragment>
                            ) : null}
                        </Space>
                        <Sort sortList={sortList} setSortList={setSortList} />
                        <Table
                            className="table"
                            columns={columnList.concat([operationColumn])}
                            rowKey="recordId"
                            data={rechargeRecordList}
                            rowSelection={{
                                type: selectType,
                                columnWidth: 50,
                                selectedRowKeys: selectedIdList,
                                onChange: (selectedRowKeys) => {
                                    setSelectedIdList(Util.getSelectedIdList(selectedIdList, rechargeRecordList, "recordId", selectedRowKeys));
                                    setSelectedList(Util.getSelectedList(selectedList, rechargeRecordList, "recordId", selectedRowKeys));
                                },
                            }}
                            scroll={{x: 50 + columnList.length * 150 + operationColumn.width}}
                            border={false}
                            pagination={false}
                            onChange={(pagination, sorter, filters) => {
                                setSortList(Util.getSortList(sortList, sorter, columnList));
                            }}
                        />
                        <div className="pagination">
                            <div className="pagination_selected">
                                {main.permissionList.indexOf(Util.md5("/recharge/record/backend/website/v1/list")) > -1 ? (
                                    <React.Fragment>
                                        <Button
                                            onClick={() => {
                                                setIsSelected(true);
                                            }}
                                        >
                                            {outletContext.intl.formatMessage({id: "global.selected"})} {selectedIdList.length}
                                        </Button>
                                    </React.Fragment>
                                ) : null}
                            </div>
                            {typeof rechargeRecordCount == "undefined" ? null : (
                                <Pagination
                                    current={rechargeRecordPageIndex}
                                    total={rechargeRecordCount}
                                    simple={main.isMobile}
                                    showTotal
                                    sizeCanChange={!main.isMobile}
                                    onChange={(pageIndex, pageSize) => {
                                        handleLoadRechargeRecordList(pageIndex, pageSize);
                                    }}
                                />
                            )}
                        </div>
                    </Card>
                </Form>
            </Scrollbars>
            {isLoad ? <Loading /> : null}
            {isDetail ? (
                <RechargeRecordDetail
                    visible={isDetail}
                    action={action}
                    recordId={recordId}
                    onClose={() => {
                        setIsDetail(false);
                    }}
                    onSubmit={() => {
                        setIsDetail(false);

                        handleLoadRechargeRecordList(rechargeRecordPageIndex, rechargeRecordPageSize);
                    }}
                />
            ) : null}
            {isProcess ? (
                <Process
                    visible={isProcess}
                    apiUrl={process.env.API_URL}
                    processModule="rechargeRecord"
                    processType={processType}
                    onClose={() => {
                        setIsProcess(false);
                    }}
                />
            ) : null}
            {isImport ? (
                <Import
                    visible={isImport}
                    name={outletContext.intl.formatMessage({id: "rechargeRecord"})}
                    templateUrl={process.env.API_URL + "/recharge/record/backend/website/v1/export/template"}
                    uploadUrl={process.env.API_URL + "/recharge/record/backend/website/v1/upload/process"}
                    startUrl={process.env.API_URL + "/recharge/record/backend/website/v1/import/process"}
                    accept=".xls,.xlsx"
                    onClose={() => {
                        setIsImport(false);
                    }}
                    onHistory={() => {
                        setIsProcess(true);
                        setProcessTyp(0);
                    }}
                />
            ) : null}
            {isSelected ? (
                <Selected
                    visible={isSelected}
                    url={process.env.API_URL + "/recharge/record/backend/website/v1/list/selected"}
                    selectedIdListKey="recordIdList"
                    selectedIdList={selectedIdList}
                    sortList={sortList}
                    rowKey="recordId"
                    columnList={columnList}
                    onClose={() => {
                        setIsSelected(false);
                    }}
                    onRemove={(idList) => {
                        setSelectedIdList(Util.removeSelectedIdList(selectedIdList, idList));
                        setSelectedList(Util.removeSelectedList(selectedList, "recordId", idList));
                    }}
                />
            ) : null}
        </React.Fragment>
    );
};

RechargeRecordIndex.propTypes = {
    autoHeight: PropTypes.bool,
    breadcrumb: PropTypes.object,
    selectType: PropTypes.string,
    onSelect: PropTypes.func,
};

export default Util.withRouter(RechargeRecordIndex);
