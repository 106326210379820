// export const iconPlay = `
// M 837.808 551.64 L 219.376 968.6 a 8 8 0 0 1 -12.48 -6.64 V 128.04 a 8 8 0 0 1 12.48 -6.624 l 618.432 416.96 a 8 8 0 0 1 0 13.264 z m -534.896 -246.8 v 480.288 L 687.52 545 L 302.912 304.84 z
// `;

export const iconPlay = `M 414.5 0.0238 c 228.9128 0 414.4762 185.5634 414.4762 414.4762 s -185.5634 414.4762 -414.4762 414.4762 S 0.0238 643.4128 0.0238 414.5 S 185.5872 0.0238 414.5 0.0238 z m 0 73.1429 C 225.9865 73.1667 73.1667 225.9865 73.1667 414.5 s 152.8198 341.3333 341.3333 341.3333 s 341.3333 -152.8198 341.3333 -341.3333 S 603.0135 73.1667 414.5 73.1667 z m -73.1429 161.4994 a 48.7619 48.7619 0 0 1 25.9901 7.5093 l 201.7524 127.1223 a 48.7619 48.7619 0 0 1 0.3657 82.2613 l -201.7524 129.6335 A 48.7619 48.7619 0 0 1 292.5952 540.1838 v -256.7314 a 48.7619 48.7619 0 0 1 48.7619 -48.7619 z m 24.381 92.9402 v 167.9116 l 131.9497 -84.7726 L 365.7381 327.6063 z`;
export const iconPause = `M 414.5 0.0238 c 228.9128 0 414.4762 185.5634 414.4762 414.4762 s -185.5634 414.4762 -414.4762 414.4762 S 0.0238 643.4128 0.0238 414.5 S 185.5872 0.0238 414.5 0.0238 z m 0 73.1429 C 225.9865 73.1667 73.1667 225.9865 73.1667 414.5 s 152.8198 341.3333 341.3333 341.3333 s 341.3333 -152.8198 341.3333 -341.3333 S 603.0135 73.1667 414.5 73.1667 z m -48.7619 195.0476 v 316.9524 h -73.1429 V 268.2143 h 73.1429 z m 158.4762 0 v 316.9524 h -73.1429 V 268.2143 h 73.1429 z`;
export const iconLeft = `M 521.29 734.276 L 230.929 448.019 L 521.29 161.762 c 37.685 -37.153 38.003 -97.625 0.707 -134.384 c -37.297 -36.758 -98.646 -36.435 -136.331 0.718 l -357.43 352.378 c -0.155 0.153 -0.297 0.314 -0.451 0.468 c -0.084 0.082 -0.172 0.157 -0.256 0.239 c -18.357 18.092 -27.581 41.929 -27.743 65.902 c -0.004 0.311 -0.017 0.623 -0.018 0.934 c 0.001 0.316 0.014 0.632 0.018 0.948 c 0.165 23.97 9.389 47.803 27.743 65.892 c 0.083 0.082 0.171 0.157 0.255 0.239 c 0.154 0.154 0.296 0.315 0.452 0.468 l 357.43 352.378 c 37.685 37.153 99.034 37.476 136.331 0.718 c 37.297 -36.758 36.979 -97.231 -0.707 -134.384 z`;

export const iconDown = `M 734.276 28.71 L 448.019 319.071 L 161.762 28.71 c -37.153 -37.685 -97.625 -38.003 -134.384 -0.707 c -36.758 37.297 -36.435 98.646 0.718 136.331 l 352.378 357.43 c 0.153 0.155 0.314 0.297 0.468 0.451 c 0.082 0.084 0.157 0.172 0.239 0.256 c 18.092 18.357 41.929 27.581 65.902 27.743 c 0.311 0.004 0.623 0.017 0.934 0.018 c 0.316 -0.001 0.632 -0.014 0.948 -0.018 c 23.97 -0.165 47.803 -9.389 65.892 -27.743 c 0.082 -0.083 0.157 -0.171 0.239 -0.255 c 0.154 -0.154 0.315 -0.296 0.468 -0.452 l 352.378 -357.43 c 37.153 -37.685 37.476 -99.034 0.718 -136.331 c -36.758 -37.297 -97.231 -36.979 -134.384 0.707 z`;

export const iconUp = `m 161.724 521.29 l 286.257 -290.361 l 286.257 290.361 c 37.153 37.685 97.625 38.003 134.384 0.707 c 36.758 -37.297 36.435 -98.646 -0.718 -136.331 l -352.378 -357.43 c -0.153 -0.155 -0.314 -0.297 -0.468 -0.451 c -0.082 -0.084 -0.157 -0.172 -0.239 -0.256 c -18.092 -18.357 -41.929 -27.581 -65.902 -27.743 c -0.311 -0.004 -0.623 -0.017 -0.934 -0.018 c -0.316 0.001 -0.632 0.014 -0.948 0.018 c -23.97 0.165 -47.803 9.389 -65.892 27.743 c -0.082 0.083 -0.157 0.171 -0.239 0.255 c -0.154 0.154 -0.315 0.296 -0.468 0.452 l -352.378 357.43 c -37.153 37.685 -37.476 99.034 -0.718 136.331 c 36.758 37.297 97.231 36.979 134.384 -0.707 z`;

export const iconRight = `M 30 163 L 320.361 449.257 L 30 735.514 c -37.685 37.153 -38.003 97.625 -0.707 134.384 c 37.297 36.758 98.646 36.435 136.331 -0.718 l 357.43 -352.378 c 0.155 -0.153 0.297 -0.314 0.451 -0.468 c 0.084 -0.082 0.172 -0.157 0.256 -0.239 c 18.357 -18.092 27.581 -41.929 27.743 -65.902 c 0.004 -0.311 0.017 -0.623 0.018 -0.934 c -0.001 -0.316 -0.014 -0.632 -0.018 -0.948 c -0.165 -23.97 -9.389 -47.803 -27.743 -65.892 c -0.083 -0.082 -0.171 -0.157 -0.255 -0.239 c -0.154 -0.154 -0.296 -0.315 -0.452 -0.468 l -357.43 -352.378 c -37.685 -37.153 -99.034 -37.476 -136.331 -0.718 c -37.297 36.758 -36.979 97.231 0.707 134.384 z`;
