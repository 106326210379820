import { PREFIX } from './base';

export const FUNNEL_TRANSFORM_RATIO = `${PREFIX}_FUNNEL_TRANSFORM_RATIO`;
export const FUNNEL_REACH_RATIO = `${PREFIX}_FUNNEL_REACH_RATIO`;
export const FUNNEL_HEIGHT_RATIO = `${PREFIX}_FUNNEL_HEIGHT_RATIO`;
export const FUNNEL_VALUE_RATIO = `${PREFIX}_FUNNEL_VALUE_RATIO`;
export const FUNNEL_LAST_VALUE_RATIO = `${PREFIX}_FUNNEL_LAST_VALUE_RATIO`;
export const FUNNEL_NEXT_VALUE_RATIO = `${PREFIX}_FUNNEL_NEXT_VALUE_RATIO`;
export const FUNNEL_LAST_VALUE = `${PREFIX}_FUNNEL_LAST_VALUE`;
export const FUNNEL_CURRENT_VALUE = `${PREFIX}_FUNNEL_CURRENT_VALUE`;
export const FUNNEL_NEXT_VALUE = `${PREFIX}_FUNNEL_NEXT_VALUE`;

export const FUNNEL_TRANSFORM_LEVEL = `${PREFIX}_FUNNEL_TRANSFORM_LEVEL`;

export const FUNNEL_LABEL_LINE_LENGTH = 20;
export const FUNNEL_LABEL_SPACE_WIDTH = 5;
export const FUNNEL_MAX_SIZE = '80%';
export const FUNNEL_MIN_SIZE = 0;
