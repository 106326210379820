export const ArcRenderContribution = Symbol.for('ArcRenderContribution');
export const AreaRenderContribution = Symbol.for('AreaRenderContribution');
export const CircleRenderContribution = Symbol.for('CircleRenderContribution');
export const GroupRenderContribution = Symbol.for('GroupRenderContribution');
export const ImageRenderContribution = Symbol.for('ImageRenderContribution');
export const PathRenderContribution = Symbol.for('PathRenderContribution');
export const PolygonRenderContribution = Symbol.for('PolygonRenderContribution');
export const RectRenderContribution = Symbol.for('RectRenderContribution');
export const SymbolRenderContribution = Symbol.for('SymbolRenderContribution');
export const TextRenderContribution = Symbol.for('TextRenderContribution');
export const CommonRenderContribution = Symbol.for('CommonRenderContribution');
export const InteractiveSubRenderContribution = Symbol.for('InteractiveSubRenderContribution');
