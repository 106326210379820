export default {
    "global.light": "亮色模式",
    "global.dark": "暗黑模式",
    "global.search": "查询",
    "global.reset": "重置",
    "global.view": "查看",
    "global.save": "新增",
    "global.new": "新增",
    "global.update": "修改",
    "global.modify": "修改",
    "global.delete": "删除",
    "global.deleteConfirmTitle": "确认删除",
    "global.deleteConfirmContent": "是否确认删除？一旦删除后将无法恢复数据！",
    "global.remove": "移除",
    "global.back": "返回",
    "global.submit": "提交",
    "global.cancel": "取消",
    "global.refresh": "刷新",
    "global.close": "关闭",
    "global.ok": "确认",
    "global.upload": "上传",
    "global.download": "下载",
    "global.import": "导入",
    "global.export": "导出",
    "global.searchTable": "搜索列表",
    "global.basicInformation": "基本信息",
    "global.pleaseEnter": "请输入",
    "global.pleaseSelect": "请选择",
    "global.page": "条/页",
    "global.prev": "上一页",
    "global.next": "下一页",
    "global.select": "选择",
    "global.selected": "选中",
    "global.selectedData": "当前选中数据",
    "global.operation": "操作",
    "global.operationRecord": "操作记录",
    "global.permission": "权限",
    "global.errorMessage": "网络异常,请稍后再试",
    "global.error": "错误提示",
    "global.notFound": "抱歉，页面不见了～",
    "global.loading": "加载中..",
    "global.export": "导出",
    "global.exportSelected": "导出选中",
    "global.exportHistory": "导出历史",
    "global.exportHistoryWarning0": "文件生成后保留72小时，请尽快下载",
    "global.exportSuccess": "导出成功",
    "global.exportFailed": "导出失败",
    "global.importData": "数据导入",
    "global.importHistory": "导入历史",
    "global.importDataWarning0": "为保证数据导入顺利，请按",
    "global.importDataWarning1": "数据模板.xlsx",
    "global.importDataWarning2": "中的规则进行导入",
    "global.importDataWarning3": "请勿修改表格标题，防止导入失败",
    "global.importDataWarning4": "当有多张工作表（sheet）时，只导入第一张工作表",
    "global.ascend": "升序",
    "global.descend": "降序",
    "global.success": "成功",
    "global.error": "失败",
    "global.required": "是必填项",
    "global.sort": "排序",
    "global.parentId": "所属上级",
    "global.childrenList": "下级列表",
    "global.treeLevel": "树形等级",
    "global.treeIndex": "树形序号",
    "global.on": "开启",
    "global.off": "关闭",
    "global.yes": "是",
    "global.no": "否",
    "global.sync": "同步",
    "global.expand": "展开",
    "global.shrink": "收起",
    "global.inverse": "反选",
    "global.login": "登录",
    "global.logout": "退出登录",
    "global.logoutConfirmTitle": "确认退出登录",
    "global.logoutConfirmContent": "是否确认退出登录？",
    "global.changePassword": "修改密码",
    "global.navigationBar": "导航栏",
    "global.organization": "组织架构",
    "global.more": "更多",
    "global.home": "首页",
    "global.createTime": "创建时间",
    "global.startTime": "开始时间",
    "global.endTime": "结束时间",
    "global.retry": "重试",
};
