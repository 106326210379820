export default {
    "global.light": "Light mode",
    "global.dark": "Dark mode",
    "global.search": "Search",
    "global.reset": "Reset",
    "global.view": "View",
    "global.save": "New",
    "global.new": "New ",
    "global.update": "Modify",
    "global.modify": "Modify ",
    "global.delete": "Delete",
    "global.remove": "Remove",
    "global.back": "Back",
    "global.submit": "Submit",
    "global.cancel": "Cancel",
    "global.refresh": "Refresh",
    "global.close": "Close",
    "global.ok": "OK",
    "global.upload": "Upload",
    "global.download": "Download",
    "global.import": "Import",
    "global.export": "Export",
    "global.searchTable": "Search Table",
    "global.basicInformation": "Basic information",
    "global.pleaseEnter": "please enter the ",
    "global.pleaseSelect": "please select the ",
    "global.page": "/ Page",
    "global.prev": "Prev",
    "global.next": "Next",
    "global.select": "Select",
    "global.selected": "Selected",
    "global.selectedData": "Currently selected data",
    "global.operation": "Operation",
    "global.operationRecord": "Operation record",
    "global.permission": "Permission",
    "global.errorMessage": "Network abnormality, please try again later",
    "global.error": "Error details",
    "global.notFound": "Whoops, this page is gone.",
    "global.loading": "Loading..",
    "global.deleteConfirmTitle": "Confirm deletion",
    "global.deleteConfirmContent": "Are you sure you want to delete it? Once deleted, data cannot be restored!",
    "global.export": "Export",
    "global.exportSelected": "Export selected",
    "global.exportHistory": "Export history",
    "global.exportHistoryWarning0": "Keep the file for 72 hours after generation, please download it as soon as possible",
    "global.exportSuccess": "Export successfully",
    "global.exportFailed": "Export failed",
    "global.importData": "Data import",
    "global.importHistory": "Import history",
    "global.importDataWarning0": "To ensure smooth data import, please follow the rules in the specification ",
    "global.importDataWarning1": "data template.xlsx ",
    "global.importDataWarning2": "import",
    "global.importDataWarning3": "Please do not modify the table title to prevent import failure",
    "global.importDataWarning4": "When there are multiple sheets, only import the first sheet",
    "global.ascend": "Ascend",
    "global.descend": "Descend",
    "global.success": "Success",
    "global.error": "Error",
    "global.required": "It is a required field",
    "global.sort": "Sort",
    "global.parentId": "Superior",
    "global.childrenList": "Children list",
    "global.treeLevel": "Tree level",
    "global.treeIndex": "Tree index",
    "global.on": "Open",
    "global.off": "Close",
    "global.yes": "Yes",
    "global.no": "No",
    "global.sync": "Sync",
    "global.expand": "Expand",
    "global.shrink": "Shrink",
    "global.inverse": "Inverse",
    "global.login": "Login",
    "global.logout": "Logout",
    "global.logoutConfirmTitle": "Confirm logout",
    "global.logoutConfirmContent": "Are you sure you want to logout?",
    "global.changePassword": "Change password",
    "global.navigationBar": "Navigation bar",
    "global.organization": "Organization",
    "global.more": "More",
    "global.home": "Home",
    "global.createTime": "Create time",
    "global.startTime": "Start time",
    "global.endTime": "End time",
    "global.retry": "Retry",
};
